















































































































































































import { Component, Prop } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'
import FormInput from '@/components/FormInput/FormInput.vue'
import Api from '@/models/Api'
import WebMessage from '@/models/WebMessage'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import User from '@/models/User'
import OneSignalVue from 'onesignal-vue'

@Component({
  components: {
    Widget,
    FormInput,
  },
})
export default class UserAccount extends ViewModel {
  public edit: string = ''

  public old_password: string = ''

  public password: string = ''

  public password_confirmation: string = ''

  @Prop({ default: null })
  public oauth!: string | null

  public push_loading: boolean = false

  public push_enabled: boolean = false

  public oauth_providers = [
    {
      name: 'Salesforce',
      key: 'salesforce',
      icon: 'salesforce',
      loading: false,
    },
    /* {
      name: 'Google',
      key: 'google',
      icon: 'google',
      loading: false,
    }, */
  ]

  public editName() {
    this.edit = 'name'
  }

  public editPassword() {
    this.edit = 'password'
    this.old_password = ''
    this.password = ''
    this.password_confirmation = ''
  }

  public cancel() {
    this.edit = ''
    this.old_password = ''
    this.password = ''
    this.password_confirmation = ''
  }

  public subscribePush() {
    this.push_loading = true
    this.$OneSignal.showNativePrompt().then(() => {
      this.$OneSignal.setSubscription(true).then(() => {
        this.push_loading = false
      })
    }).catch(() => {
      this.push_loading = false
    })
  }

  public unsubscribePush() {
    this.push_loading = true
    this.$OneSignal.setSubscription(false).then(() => {
      this.push_loading = false
    })
  }

  public connectOAuth(provider: string) {
    window.location.href = `${process.env.VUE_APP_BASE_API_URL}oauth/${provider}/login`
  }

  public disconnectOAuth(provider: string) {
    const active_provider = this.oauth_providers.find(p => p.key == provider)
    this.oauth_providers.forEach(p => {
      if (p.key == provider) {
        p.loading = true
      }
    })

    const api = new Api()
    api
      .get(`oauth/${provider}/logout`)
      .then(response => {
        this.oauth_providers.forEach(p => {
          if (p.key == provider) {
            p.loading = false
          }
        })
        if (response.status == 200) {
          const system = getModule(SystemtModule)
          system.updateState({
            name: 'user',
            data: User.toObject(response.data.result.user),
          })
          if (active_provider) {
            WebMessage.success(`Successfully disconnected from ${active_provider.name}.`)
          }
        }
      })
      .catch(error => {
        this.oauth_providers.forEach(p => {
          if (p.key == provider) {
            p.loading = false
          }
        })
        WebMessage.success('We were not able to process your request, please try again later.')
      })
  }

  public completeOAtuh() {
    const api = new Api()
    const { query } = this.$route
    const provider = this.oauth_providers.find(p => p.key == this.oauth)

    this.oauth_providers.forEach(p => {
      if (p.key == this.oauth) {
        p.loading = true
      }
    })

    api
      .get(`oauth/${this.oauth}/callback?code=${query.code}`)
      .then(response => {
        this.oauth_providers.forEach(p => {
          if (p.key == this.oauth) {
            p.loading = false
          }
        })
        if (response.status == 200) {
          const system = getModule(SystemtModule)
          system.updateState({
            name: 'user',
            data: User.toObject(response.data.result.user),
          })
          if (provider) WebMessage.success(`Successfully connected to ${provider.name}.`)
          this.$router.push('/app/account')
        }
      })
      .catch(error => {
        this.oauth_providers.forEach(p => {
          if (p.key == this.oauth) {
            p.loading = false
          }
        })
        WebMessage.success('We were not able to process your request, please try again later.')
      })
  }

  public onSubmit() {
    if (this.edit == 'password') {
      this.user
        .changePassword({
          old_password: this.old_password,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then(response => {
          if (response.status == 200) {
            this.edit = ''
          }
        })
    } else {
      // this.user.updateName();
    }
  }

  public created() {
    if (this.oauth) {
      this.completeOAtuh()
    }

    // @ts-ignore
    this.$OneSignal.on('subscriptionChange', (isSubscribed: any) => {
      this.push_loading = false
      if (isSubscribed) {
        this.$OneSignal.setExternalUserId(this.user.id)
      }
    })

    this.$OneSignal.isPushNotificationsEnabled().then(isEnabled => {
      this.push_enabled = isEnabled
    })
  }
}
